import { relayStylePagination } from '@apollo/client/utilities';

import { StrictTypedTypePolicies } from 'src/generated/apollo_helpers';
import { ExhaustiveArray } from 'types';
import {
  Country,
  Currency,
  HasActivitiesActivitiesArgs,
  IncomingEmailIncomingEmailAttachmentsArgs,
  IndexAuditsQueryVariables,
  IndexCommentsQueryVariables,
  IndexDataFiltersQueryVariables,
  IndexDataScopingRulesQueryVariables,
  IndexDataSourceItemsQueryVariables,
  IndexDataViewFieldsQueryVariables,
  IndexReportScheduleSubscriberNamesQueryVariables,
  IndexReportScheduleSubscriberResourcesQueryVariables,
  Language,
  NotificationSetting,
  QueryActivitiesArgs,
  QueryApprovalDelegationsArgs,
  QueryApprovalsArgs,
  QueryBankAccountsArgs,
  QueryCardAuthorisationsArgs,
  QueryCardSubscriptionsArgs,
  QueryCategoriesArgs,
  QueryCategorySetsArgs,
  QueryCostCentresArgs,
  QueryCustomFieldsArgs,
  QueryDataSourcesArgs,
  QueryDataViewsArgs,
  QueryDocumentsArgs,
  QueryInboxesArgs,
  QueryIncomingEmailsArgs,
  QueryInvoicePaymentsArgs,
  QueryIssuerIntegrationConsumersArgs,
  QueryIssuerIntegrationRulesArgs,
  QueryIssuerIntegrationsArgs,
  QueryLocationsArgs,
  QueryMerchantCategoryCodesArgs,
  QueryMerchantsArgs,
  QueryOrganisationUnitsArgs,
  QueryPurchaseOrdersArgs,
  QueryPurchaseRequestItemsArgs,
  QueryReportsArgs,
  QueryReportSchedulesArgs,
  QuerySpendLimitsArgs,
  QuerySuppliersArgs,
  QueryTransactionsArgs,
  QueryUsersArgs,
  QueryVirtualCardsArgs,
  QueryWorkflowRunsArgs,
  QueryWorkflowsArgs,
  SelectOptionsQueryVariables,
  SupplierPayment,
  TriggerEvent,
  WorkflowActionsArgs,
} from 'graphql-api/generated-types';
import { _mergeArray, _true, customTransactionReconciliationStatus, parsedId } from './fieldPolicies';

type CommonConnectionArgs = 'after' | 'before' | 'first' | 'last';
type ActivitiesKeyArgs = Exclude<keyof QueryActivitiesArgs, CommonConnectionArgs>;
type ApprovalDelegationsKeyArgs = Exclude<keyof QueryApprovalDelegationsArgs, CommonConnectionArgs>;
type ApprovalsKeyArgs = Exclude<keyof QueryApprovalsArgs, CommonConnectionArgs>;
type AuditsKeyArgs = Exclude<keyof IndexAuditsQueryVariables, 'endCursor'>;
type BankAccountsKeyArgs = Exclude<keyof QueryBankAccountsArgs, CommonConnectionArgs>;
type CardAuthorisationsKeyArgs = Exclude<keyof QueryCardAuthorisationsArgs, CommonConnectionArgs>;
type CardSubscriptionsKeyArgs = Exclude<keyof QueryCardSubscriptionsArgs, CommonConnectionArgs>;
type CategoriesKeyArgs = Exclude<keyof QueryCategoriesArgs, CommonConnectionArgs>;
type CategorySetsKeyArgs = Exclude<keyof QueryCategorySetsArgs, CommonConnectionArgs>;
type CommentsKeyArgs = Exclude<keyof IndexCommentsQueryVariables, 'startCursor'>;
type CostCentresKeyArgs = Exclude<keyof QueryCostCentresArgs, CommonConnectionArgs>;
type CustomFieldsKeyArgs = Exclude<keyof QueryCustomFieldsArgs, CommonConnectionArgs>;
type DataFiltersKeyArgs = Exclude<keyof IndexDataFiltersQueryVariables, 'endCursor'>;
type DataScopingRulesKeyArgs = Exclude<keyof IndexDataScopingRulesQueryVariables, 'endCursor'>;
type DataSourcesKeyArgs = Exclude<keyof QueryDataSourcesArgs, CommonConnectionArgs>;
type DataSourceItemsKeyArgs = Exclude<keyof IndexDataSourceItemsQueryVariables, 'endCursor'>;
type DataViewsKeyArgs = Exclude<keyof QueryDataViewsArgs, CommonConnectionArgs>;
type DataViewFieldsKeyArgs = Exclude<keyof IndexDataViewFieldsQueryVariables, 'endCursor'>;
type DocumentsKeyArgs = Exclude<keyof QueryDocumentsArgs, CommonConnectionArgs>;
type FlowStepActionsKeyArgs = Exclude<keyof WorkflowActionsArgs, CommonConnectionArgs>;
type HasActivitiesActivitiesKeyArgs = Exclude<keyof HasActivitiesActivitiesArgs, CommonConnectionArgs>;
type InboxesKeyArgs = Exclude<keyof QueryInboxesArgs, CommonConnectionArgs>;
type IncomingEmailsKeyArgs = Exclude<keyof QueryIncomingEmailsArgs, CommonConnectionArgs>;
type IncomingEmailIncomingEmailAttachmentsKeyArgs = Exclude<
  keyof IncomingEmailIncomingEmailAttachmentsArgs,
  CommonConnectionArgs
>;
type InvoicePaymentsKeyArgs = Exclude<keyof QueryInvoicePaymentsArgs, CommonConnectionArgs>;
type IssuerIntegrationsKeyArgs = Exclude<keyof QueryIssuerIntegrationsArgs, CommonConnectionArgs>;
type IssuerIntegrationConsumersKeyArgs = Exclude<keyof QueryIssuerIntegrationConsumersArgs, CommonConnectionArgs>;
type IssuerIntegrationRulesKeyArgs = Exclude<keyof QueryIssuerIntegrationRulesArgs, CommonConnectionArgs>;
type LocationsKeyArgs = Exclude<keyof QueryLocationsArgs, CommonConnectionArgs>;
type MerchantCategoryCodesKeyArgs = Exclude<keyof QueryMerchantCategoryCodesArgs, CommonConnectionArgs>;
type MerchantsKeyArgs = Exclude<keyof QueryMerchantsArgs, CommonConnectionArgs>;
type OrganisationUnitsKeyArgs = Exclude<keyof QueryOrganisationUnitsArgs, CommonConnectionArgs>;
type PurchaseOrdersKeyArgs = Exclude<keyof QueryPurchaseOrdersArgs, CommonConnectionArgs>;
type PurchaseRequestItemsKeyArgs = Exclude<keyof QueryPurchaseRequestItemsArgs, CommonConnectionArgs>;
type ReportScheduleNotificationSubscribersKeyArgs = Exclude<
  keyof IndexReportScheduleSubscriberNamesQueryVariables | keyof IndexReportScheduleSubscriberResourcesQueryVariables,
  'endCursor'
>;
type ReportsKeyArgs = Exclude<keyof QueryReportsArgs, CommonConnectionArgs>;
type ReportTemplatesKeyArgs = Exclude<keyof QueryReportSchedulesArgs, CommonConnectionArgs>;
type SpendLimitsKeyArgs = Exclude<keyof QuerySpendLimitsArgs, CommonConnectionArgs>;
type SuppliersKeyArgs = Exclude<keyof QuerySuppliersArgs, CommonConnectionArgs>;
type TransactionsKeyArgs = Exclude<keyof QueryTransactionsArgs, CommonConnectionArgs>;
type UsersKeyArgs = Exclude<keyof QueryUsersArgs, CommonConnectionArgs>;
type VirtualCardsKeyArgs = Exclude<keyof QueryVirtualCardsArgs, CommonConnectionArgs>;
type WorkflowsKeyArgs = Exclude<keyof QueryWorkflowsArgs, CommonConnectionArgs>;
type WorkflowActionsKeyArgs = Exclude<keyof WorkflowActionsArgs, CommonConnectionArgs>;
type WorkflowRunsKeyArgs = Exclude<keyof QueryWorkflowRunsArgs, CommonConnectionArgs>;

const activitiesKeyArgs = ExhaustiveArray<ActivitiesKeyArgs>()('includeRelated', 'sort', 'trackableId');
const approvalDelegationsKeyArgs = ExhaustiveArray<ApprovalDelegationsKeyArgs>()(
  'archived',
  'delegateFromIds',
  'delegateToIds',
  'endsBefore',
  'search',
  'sort',
  'statuses',
  'startsAfter'
);
const approvalsKeyArgs = ExhaustiveArray<ApprovalsKeyArgs>()(
  'approvableIds',
  'approvableTypes',
  'approvalDelegationIds',
  'assigneeIds',
  'delegatedFromIds',
  'delegatedToIds',
  'isDelegated',
  'search',
  'sort',
  'statuses'
);
const auditsKeyArgs = ExhaustiveArray<AuditsKeyArgs>()('id');
const bankAccountsKeyArgs = ExhaustiveArray<BankAccountsKeyArgs>()('archived');
const cardAuthorisationsKeyArgs = ExhaustiveArray<CardAuthorisationsKeyArgs>()(
  'amountCentsGreaterThan',
  'amountCentsLessThan',
  'cardSubscriptionIds',
  'dateAfter',
  'dateBefore',
  'declined',
  'purchaseOrderIds',
  'requestedByIds',
  'search',
  'sort',
  'virtualCardIds'
);
const cardSubscriptionsKeyArgs = ExhaustiveArray<CardSubscriptionsKeyArgs>()(
  'anyCardStatus',
  'closed',
  'ownerIds',
  'reconciliationStatuses',
  'search',
  'sort',
  'withSimilarSubscriptions'
);
const categoriesKeyArgs = ExhaustiveArray<CategoriesKeyArgs>()(
  'archived',
  'categorySetId',
  'parentId',
  'search',
  'sort'
);
const categorySetsKeyArgs = ExhaustiveArray<CategorySetsKeyArgs>()('archived', 'sort');
const commentsKeyArgs = ExhaustiveArray<CommentsKeyArgs>()('parentId');
const costCentresKeyArgs = ExhaustiveArray<CostCentresKeyArgs>()('archived', 'search', 'sort');
const customFieldsKeyArgs = ExhaustiveArray<CustomFieldsKeyArgs>()(
  'archived',
  'createdByIds',
  'formableClasses',
  'formableParentIds',
  'required',
  'search',
  'sort',
  'types'
);
const dataFiltersKeyArgs = ExhaustiveArray<DataFiltersKeyArgs>()('id');
const dataScopingRulesKeyArgs = ExhaustiveArray<DataScopingRulesKeyArgs>()('id');
const dataSourcesKeyArgs = ExhaustiveArray<DataSourcesKeyArgs>()('archived', 'search', 'sort');
const dataSourceItemsKeyArgs = ExhaustiveArray<DataSourceItemsKeyArgs>()('dataSourceId', 'search', 'sort');
const dataViewsKeyArgs = ExhaustiveArray<DataViewsKeyArgs>()('archived', 'resourceTypes', 'sort');
const dataViewFieldsKeyArgs = ExhaustiveArray<DataViewFieldsKeyArgs>()('id');
const documentsKeyArgs = ExhaustiveArray<DocumentsKeyArgs>()(
  'archived',
  'cardSubscriptionIds',
  'createdByIds',
  'documentTypes',
  'hasEmailAttachment',
  'inboxIds',
  'isDraft',
  'paymentDateAfter',
  'paymentDateBefore',
  'purchaseOrderIds',
  'search',
  'sort',
  'transactionIds',
  'withTransactions'
);
const flowStepActionsKeyArgs = ExhaustiveArray<FlowStepActionsKeyArgs>()('types');
const hasActivitiesActivitiesKeyArgs = ExhaustiveArray<HasActivitiesActivitiesKeyArgs>()('includeRelated', 'sort');
const inboxesKeyArgs = ExhaustiveArray<InboxesKeyArgs>()('keys', 'ownerIds', 'recordIds', 'search');
const incomingEmailsKeyArgs = ExhaustiveArray<IncomingEmailsKeyArgs>()(
  'inboxIds',
  'processed',
  'search',
  'sort',
  'withAttachments'
);
const incomingEmailIncomingEmailAttachmentsKeyArgs =
  ExhaustiveArray<IncomingEmailIncomingEmailAttachmentsKeyArgs>()('sort');
const invoicePaymentsKeyArgs = ExhaustiveArray<InvoicePaymentsKeyArgs>()(
  'createdByIds',
  'issuerIntegrationIds',
  'purchaseOrderIds',
  'search',
  'sort',
  'statuses',
  'supplierBankAccountIds',
  'supplierIds'
);
const issuerIntegrationsKeyArgs = ExhaustiveArray<IssuerIntegrationsKeyArgs>()(
  'anyCurrencyCode',
  'archived',
  'cardUseInvoice',
  'cardUseMulti',
  'cardUseSingle',
  'name',
  'search',
  'sort'
);
const issuerIntegrationConsumersKeyArgs = ExhaustiveArray<IssuerIntegrationConsumersKeyArgs>()(
  'cardUseInvoiceFallback',
  'cardUseMultiFallback',
  'cardUseSingleFallback',
  'consumerRelation',
  'consumerTypes',
  'costCentreIds',
  'issuerIntegrationIds',
  'organisationUnitIds',
  'sort',
  'userIds'
);
const issuerIntegrationRulesKeyArgs = ExhaustiveArray<IssuerIntegrationRulesKeyArgs>()('cardUseTypes', 'sort');
const locationsKeyArgs = ExhaustiveArray<LocationsKeyArgs>()('archived', 'sort');
const merchantCategoryCodesKeyArgs = ExhaustiveArray<MerchantCategoryCodesKeyArgs>()('search', 'sort');
const merchantsKeyArgs = ExhaustiveArray<MerchantsKeyArgs>()(
  'cardSubscriptionIds',
  'categoryCodes',
  'countryCodes',
  'purchaseOrderIds',
  'search',
  'sort',
  'transactionIds'
);
const organisationUnitsKeyArgs = ExhaustiveArray<OrganisationUnitsKeyArgs>()(
  'archived',
  'locationId',
  'parentId',
  'search',
  'sort'
);
const purchaseOrdersKeyArgs = ExhaustiveArray<PurchaseOrdersKeyArgs>()(
  'purchaseType',
  'reconciliationStatuses',
  'requestedByIds',
  'search',
  'sort',
  'supplierId'
);
const purchaseRequestItemsKeyArgs = ExhaustiveArray<PurchaseRequestItemsKeyArgs>()(
  'anyApprovalAssignee',
  'anyApprovalStatus',
  'anyPurchaseTypes',
  'anyStatus',
  'isDraft',
  'isRequestedByMe',
  'reconciliationStatuses',
  'requestedBaseAmountCentsGreaterThan',
  'requestedBaseAmountCentsLessThan',
  'requestedByIds',
  'search',
  'sort',
  'withPurchaseOrders'
);
const reportScheduleNotificationSubscribersKeyArgs =
  ExhaustiveArray<ReportScheduleNotificationSubscribersKeyArgs>()('id');
const reportsKeyArgs = ExhaustiveArray<ReportsKeyArgs>()('createdByIds', 'dataViewIds', 'search', 'sort');
const reportTemplatesKeyArgs = ExhaustiveArray<ReportTemplatesKeyArgs>()('archived', 'sort');
const spendLimitsKeyArgs = ExhaustiveArray<SpendLimitsKeyArgs>()('scope', 'sort', 'spendLimitable');
const suppliersKeyArgs = ExhaustiveArray<SuppliersKeyArgs>()(
  'activatedByIds',
  'countryCodes',
  'disabled',
  'industryCodeIds',
  'onboardingStatuses',
  'search',
  'sort'
);
const transactionsKeyArgs = ExhaustiveArray<TransactionsKeyArgs>()(
  'documentId',
  'merchantCategoryCodes',
  'merchantCountryCodes',
  'merchantIds',
  'purchaseOrderId',
  'reconciliationStatus',
  'requestedByIds',
  'search',
  'sort',
  'subscriptionIds',
  'supplierId',
  'types',
  'virtualCardId'
);
const usersKeyArgs = ExhaustiveArray<UsersKeyArgs>()(
  'anyStatus',
  'awaitingInvitations',
  'organisationUnitIds',
  'permissions',
  'search',
  'sort',
  'systemUsers'
);
const virtualCardsKeyArgs = ExhaustiveArray<VirtualCardsKeyArgs>()(
  'aboutToExpire',
  'anyCurrencyCodes',
  'anyDeliveryMethod',
  'anyVirtualCardStatus',
  'hasTransactions',
  'issuedToIds',
  'lowAmount',
  'search',
  'sort',
  'subscriptionIds'
);
const workflowActionsKeyArgs = ExhaustiveArray<WorkflowActionsKeyArgs>()('types');
const workflowRunsKeyArgs = ExhaustiveArray<WorkflowRunsKeyArgs>()('anyStatus', 'recordIds', 'sort', 'workflowIds');
const workflowsKeyArgs = ExhaustiveArray<WorkflowsKeyArgs>()(
  'archived',
  'createdByIds',
  'isEnabled',
  'isPublished',
  'search',
  'sort',
  'statuses'
);

/**
 * @see SelectOptionsQuery
 */
const selectOptionsKeyArgs = ExhaustiveArray<Exclude<keyof SelectOptionsQueryVariables, 'endCursor'>>()(
  'id',
  'scopeToFormableId',
  'search'
);

/**
 * Customizing primary ids
 * @see https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
 */
const countryKeyField: keyof Pick<Country, 'code'> = 'code' as const;
const currencyKeyField: keyof Pick<Currency, 'code'> = 'code' as const;
const languageKeyField: keyof Pick<Language, 'code'> = 'code' as const;
const notificationSettingKeyField: keyof Pick<NotificationSetting, 'activityKey'> = 'activityKey' as const;
const supplierPaymentKeyField: keyof Pick<SupplierPayment, 'transactionReference'> = 'transactionReference' as const;
const triggerEventKeyField: keyof Pick<TriggerEvent, 'key'> = 'key' as const;

/**
 * @see StrictTypedTypePolicies
 * @see https://www.graphql-code-generator.com/plugins/typescript/typescript-apollo-client-helpers
 */
export const typePolicies: StrictTypedTypePolicies = {
  Approval: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      approvalsUsers: relayStylePagination(),
      assignees: relayStylePagination(),
      comments: relayStylePagination(commentsKeyArgs),
    },
  },
  ApprovalDelegation: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      comments: relayStylePagination(commentsKeyArgs),
    },
  },
  ApproverCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      dataScopingRules: relayStylePagination(dataScopingRulesKeyArgs),
      options: relayStylePagination(selectOptionsKeyArgs),
    },
  },
  AttachmentCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  BuyerAccount: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  CardAuthorisation: {
    fields: {
      /**
       * Same as in Transaction, amount never changes once it is set.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      amount: {
        merge: true,
      },
    },
  },
  CardSubscription: {
    fields: {
      documents: relayStylePagination(documentsKeyArgs),
      purchaseOrders: relayStylePagination(purchaseOrdersKeyArgs),
      purchaseRequestItems: relayStylePagination(purchaseRequestItemsKeyArgs),
      transactions: relayStylePagination(transactionsKeyArgs),
    },
  },
  Category: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  CategoryCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      dataScopingRules: relayStylePagination(dataScopingRulesKeyArgs),
      options: relayStylePagination(selectOptionsKeyArgs),
    },
  },
  CategoryRecordViewerPermissions: {
    fields: {
      createChild: _true,
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  CategorySet: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  CheckboxCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  Comment: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  CostCentre: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  CostCentreCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      dataScopingRules: relayStylePagination(dataScopingRulesKeyArgs),
      options: relayStylePagination(selectOptionsKeyArgs),
    },
  },
  CostCentreRecordViewerPermissions: {
    fields: {
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  Country: {
    keyFields: [countryKeyField],
  },
  Currency: {
    keyFields: [currencyKeyField],
  },
  CustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  CustomFieldValue: {
    fields: {
      audits: relayStylePagination(auditsKeyArgs),
    },
  },
  DataSource: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      items: relayStylePagination(dataSourceItemsKeyArgs),
      parsedId,
    },
  },
  DataSourceItem: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  DataSourceItemRecordViewerPermissions: {
    fields: {
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  DataValue: {
    fields: {
      /**
       * Amount value can be safely merged,
       * because we use only one attribute "universalFormat".
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      amountValue: {
        merge: true,
      },
    },
  },
  DataView: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      fields: relayStylePagination(dataViewFieldsKeyArgs),
      filters: relayStylePagination(dataFiltersKeyArgs),
      parsedId,
    },
  },
  DateCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  Document: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      comments: relayStylePagination(commentsKeyArgs),
      supportedTransactions: relayStylePagination(),
      transactions: _mergeArray,
    },
  },
  DocumentRecordViewerPermissions: {
    fields: {
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  FlowActionApprove: {
    fields: {
      inputFalseSteps: relayStylePagination(),
      inputTrueSteps: relayStylePagination(),
    },
  },
  FlowActionComment: {
    fields: {
      inputFalseSteps: relayStylePagination(),
      inputTrueSteps: relayStylePagination(),
    },
  },
  FlowActionCreatePurchaseOrder: {
    fields: {
      inputFalseSteps: relayStylePagination(),
      inputTrueSteps: relayStylePagination(),
    },
  },
  FlowActionGetApproval: {
    fields: {
      approverUsers: relayStylePagination(usersKeyArgs),
      assigneePaths: relayStylePagination(flowStepActionsKeyArgs),
      inputFalseSteps: relayStylePagination(),
      inputTrueSteps: relayStylePagination(),
    },
  },
  FlowActionReject: {
    fields: {
      inputFalseSteps: relayStylePagination(),
      inputTrueSteps: relayStylePagination(),
    },
  },
  FlowStepCondition: {
    fields: {
      inputFalseSteps: relayStylePagination(),
      inputTrueSteps: relayStylePagination(),
      referenceAssociations: relayStylePagination(),
      referencePaths: relayStylePagination(),
    },
  },
  FlowStepResult: {
    fields: {
      conditionReferenceValues: relayStylePagination(),
    },
  },
  IncomingEmail: {
    fields: {
      incomingEmailAttachments: relayStylePagination(incomingEmailIncomingEmailAttachmentsKeyArgs),
    },
  },
  IntegerCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  InvoicePayment: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      /**
       * Timestamps {@link CardPayment.completedAt} and {@link CardPayment.scheduledAt} are coming from the Payment App.
       * There is no guarantee of immutability, but changes of these timestamps are unlikely.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      cardPayment: {
        merge: true,
      },
      /**
       * Requested payout amount is filled with the document's total amount when invoice payment is created
       * and then doesn't change.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      requestedPayoutAmount: {
        merge: true,
      },
    },
  },
  IssuerIntegration: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  IssuerIntegrationConsumer: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  IssuerIntegrationConsumerRecordViewerPermissions: {
    fields: {
      makeInvoiceFallback: _true,
      makeMultiFallback: _true,
      makeSingleFallback: _true,
    },
  },
  IssuerIntegrationRule: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  Language: {
    keyFields: [languageKeyField],
  },
  Location: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  Me: {
    fields: {
      notifications: relayStylePagination(),
    },
  },
  Merchant: {
    fields: {
      totalSpent: {
        merge: true,
      },
    },
  },
  MoneyCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  NotificationSetting: {
    keyFields: [notificationSettingKeyField],
  },
  OrganisationUnit: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  OrganisationUnitCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      dataScopingRules: relayStylePagination(dataScopingRulesKeyArgs),
      options: relayStylePagination(selectOptionsKeyArgs),
    },
  },
  OrganisationUnitRecordViewerPermissions: {
    fields: {
      createChild: _true,
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  PurchaseOrder: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      comments: relayStylePagination(commentsKeyArgs),
      documents: relayStylePagination(documentsKeyArgs),
      purchaseAmount: { merge: true },
    },
  },
  PurchaseRequestItem: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      comments: relayStylePagination(commentsKeyArgs),
      customFieldValues: _mergeArray,
      /**
       * Requested amount can be safely merged only after submitting purchase request item.
       * Use of fragment Money is recommended in queries and mutations of DRAFT purchase request item.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      requestedAmount: { merge: true },
      requestedBaseAmount: { merge: true },
    },
  },
  PurchaseRequestItemRecordViewerPermissions: {
    fields: {
      closePurchaseOrder: _true,
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  Query: {
    fields: {
      activities: relayStylePagination(activitiesKeyArgs),
      approvalDelegations: relayStylePagination(approvalDelegationsKeyArgs),
      approvals: relayStylePagination(approvalsKeyArgs),
      bankAccounts: relayStylePagination(bankAccountsKeyArgs),
      cardAuthorisations: relayStylePagination(cardAuthorisationsKeyArgs),
      cardSubscriptions: relayStylePagination(cardSubscriptionsKeyArgs),
      categories: relayStylePagination(categoriesKeyArgs),
      categorySets: relayStylePagination(categorySetsKeyArgs),
      costCentres: relayStylePagination(costCentresKeyArgs),
      customFields: relayStylePagination(customFieldsKeyArgs),
      dataSources: relayStylePagination(dataSourcesKeyArgs),
      dataViews: relayStylePagination(dataViewsKeyArgs),
      dataViewsMetadata: relayStylePagination(),
      documents: relayStylePagination(documentsKeyArgs),
      inboxes: relayStylePagination(inboxesKeyArgs),
      incomingEmails: relayStylePagination(incomingEmailsKeyArgs),
      invoicePayments: relayStylePagination(invoicePaymentsKeyArgs),
      issuerIntegrationConsumers: relayStylePagination(issuerIntegrationConsumersKeyArgs),
      issuerIntegrationRules: relayStylePagination(issuerIntegrationRulesKeyArgs),
      issuerIntegrations: relayStylePagination(issuerIntegrationsKeyArgs),
      locations: relayStylePagination(locationsKeyArgs),
      merchantCategoryCodes: relayStylePagination(merchantCategoryCodesKeyArgs),
      merchants: relayStylePagination(merchantsKeyArgs),
      organisationUnits: relayStylePagination(organisationUnitsKeyArgs),
      purchaseOrders: relayStylePagination(purchaseOrdersKeyArgs),
      purchaseRequestItems: relayStylePagination(purchaseRequestItemsKeyArgs),
      reports: relayStylePagination(reportsKeyArgs),
      reportSchedules: relayStylePagination(reportTemplatesKeyArgs),
      spendLimits: relayStylePagination(spendLimitsKeyArgs),
      suppliers: relayStylePagination(suppliersKeyArgs),
      transactions: relayStylePagination(transactionsKeyArgs),
      users: relayStylePagination(usersKeyArgs),
      virtualCards: relayStylePagination(virtualCardsKeyArgs),
      workflowRuns: relayStylePagination(workflowRunsKeyArgs),
      workflows: relayStylePagination(workflowsKeyArgs),
    },
  },
  Report: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      parsedId,
    },
  },
  ReportRecordViewerPermissions: {
    fields: {
      show: _true,
    },
  },
  ReportSchedule: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      notificationSubscribers: relayStylePagination(reportScheduleNotificationSubscribersKeyArgs),
      parsedId,
      range: { merge: true },
    },
  },
  SelectCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      options: relayStylePagination(selectOptionsKeyArgs),
    },
  },
  SupplierAccount: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      /**
       * Change of supplier address doesn't happen daily.
       * In most cases address is relevant for years.
       * Country will most definitely not change.
       */
      address: {
        merge: true,
      },
    },
  },
  SupplierAccountRecordViewerPermissions: {
    fields: {
      showDetails: _true,
    },
  },
  SupplierPayment: {
    keyFields: [supplierPaymentKeyField],
  },
  TextAreaCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  TextCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  Transaction: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      /**
       * Billed amount is filled when the transaction is created. Then it never changes.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      billedAmount: { merge: true },
      billedBaseAmount: { merge: true },
      customReconciliationStatus: customTransactionReconciliationStatus,
      /**
       * {@link Transaction.originalAmount} is filled when the transaction is created.
       * The difference between original and billing amounts is foreign exchange if any exists.
       */
      originalAmount: { merge: true },
      parsedId,
      supportedDocuments: relayStylePagination(documentsKeyArgs),
      supportedDocumentTypes: _mergeArray,
    },
  },
  TransactionRecordViewerPermissions: {
    fields: {
      addDocument: _true,
    },
  },
  TriggerEvent: {
    keyFields: [triggerEventKeyField],
  },
  UrlCustomField: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
    },
  },
  User: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      parsedId,
    },
  },
  UserRecordViewerPermissions: {
    fields: {
      showArchivedCustomFields: _true,
      showEmptyCustomFields: _true,
    },
  },
  VirtualCard: {
    fields: {
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      /**
       * Actual limit is set when the card is issued, and then it is never updated.
       * There is nothing like reissuing the card with the same credentials.
       * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
       */
      actualLimit: { merge: true },
      actualLimitBaseAmount: { merge: true },
      /**
       * {@link VirtualCard.requestedLimit} is filled with PO's purchase amount
       * which is coming from approved amount but with currency conversion.
       */
      requestedLimit: { merge: true },
      spentBaseAmount: { merge: true },
    },
  },
  Workflow: {
    fields: {
      actions: relayStylePagination(workflowActionsKeyArgs),
      activities: relayStylePagination(hasActivitiesActivitiesKeyArgs),
      conditions: relayStylePagination(),
      versions: relayStylePagination(workflowsKeyArgs),
      /**
       * Fragment WorkflowViewerPermissions can not be used in workflows connection due to error 500
       * @see https://app.clickup.com/t/2179883/MP-7284
       * @todo remove temporary field policy when error 500 is fixed
       */
      viewerPermissions: { merge: true },
    },
  },
  WorkflowRun: {
    fields: {
      flowStepResults: relayStylePagination(),
    },
  },
};
